import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { Element, scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import remarkGfm from 'remark-gfm';
import { Layouts, Button } from '../components/components';
import { FontWeight, pathName } from '../lib/constants/enums';
import { pathTo, replaceStrapiComponentName } from '../lib/utils';
import { fENav } from '../lib/constants/fakedata';

import style from './fitnessEquipment.module.scss';
import { getAllProducts, getDeliveries } from '../api/strapi';
import { StrapiProducts, StrapiDeliveries } from '../graphql-types';
import { colors } from '../styles/variables';
import ColorService from '../lib/services/colorService';

import { StrapiBlocksName } from '../lib/constants/strapi';

interface OptionItemprops {
  title: string;
  children: JSX.Element | React.FC;
  isHidden?: boolean;
}

enum deliveryType {
  delivery = 'delivery',
  refund = 'refund',
}

const OptionItem: React.FC<OptionItemprops> = ({ title, children, isHidden = true }) => {
  const [hidden, sethidden] = useState<boolean>(isHidden);
  return (
    <div className={style.option}>
      <div className={cx(style.optionLabel, { [style.optionLabelOpen]: !hidden })} onClick={() => sethidden(prev => !prev)}>
        <h3 className={style.optionTitle}>{title}</h3>
        <img src={`${process.env.STRAPI_API_URL}/uploads/Vector_f6d1dff362.png`} className={cx({ [style.activeArrow]: !hidden })} alt="" />
      </div>
      {(!hidden && children) || null}
    </div>
  );
};

const FitnessEquipmentPage: React.FC = () => {
  const { IndexLayout, Page, LayoutBG, Container, AdaptiveLayout } = Layouts;
  const [path, setPath] = useState<pathName>(pathName.fitnessEquipment);
  const [products, setProducts] = useState<StrapiProducts[] | null>(null);
  const [delivery, setDelivery] = useState<StrapiDeliveries | null>(null);
  const [refund, setRefund] = useState<StrapiDeliveries | null>(null);
  const colorService = new ColorService();
  const isMD = useMediaQuery({ minWidth: 766 });
  const { t } = useTranslation();

  useEffect(() => {
    if (path === pathName.fitnessEquipment && !products) getAllProducts().then(res => setProducts(res));
    if (path === pathName.delivery && !delivery)
      getDeliveries().then(res => setDelivery(res.find((item: StrapiDeliveries) => item.type === deliveryType.delivery)));
    if (path === pathName.refund && !refund)
      getDeliveries().then(res => setRefund(res.find((item: StrapiDeliveries) => item.type === deliveryType.refund)));
  }, [path]);

  const settings = {
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  const StyledProductTitle = styled.div`
    & strong {
      color: ${props => props.color};
    }
  `;

  const productsList = (
    <div className={style.productsList}>
      {products ? (
        <p>{t('shell:fitnessEquipment.asSoonAsPossible')}</p>
      ) : (
        // products.map(product => (
        //   <div
        //     style={{
        //       background: (product.theme?.mainColor && colorService.hexToRGB(product.theme?.mainColor, 0.2)) || colors.white,
        //     }}
        //     className={style.productItem}
        //     key={product.id}
        //   >
        //     <div className={style.productSlider}>
        //       <Slider {...settings} customPaging={() => <div className={style.pagingItem} />}>
        //         {product.productInfo?.sliderImages &&
        //           product.productInfo?.sliderImages.map((slide, index) => (
        //             <div key={index}>
        //               <img src={`${process.env.STRAPI_API_URL}${slide?.picture?.url}`} alt="" />
        //             </div>
        //           ))}
        //       </Slider>
        //     </div>
        //     <div className={style.productContent}>
        //       <StyledProductTitle color={product.theme?.mainColor || colors.black} className={style.productName}>
        //         <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={product?.productInfo?.title || ''} remarkPlugins={[remarkGfm]} />
        //       </StyledProductTitle>
        //       <div className={style.productDescription}>
        //         <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={product?.productInfo?.description || ''} remarkPlugins={[remarkGfm]} />
        //       </div>
        //       <div className={style.productTrainingLoad}>
        //         {product?.productInfo?.hashtag &&
        //           product.productInfo.hashtag.map((tag, idx) => (
        //             <div className={cx(style.hashtag, { [style[tag?.load || 'basic']]: tag?.load })} key={tag?.id || idx}>
        //               {tag?.label}
        //             </div>
        //           ))}
        //       </div>
        //       <div className={style.productPrice}>
        //         <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={product?.productInfo?.price || ''} remarkPlugins={[remarkGfm]} />
        //       </div>
        //       <Link className={style.productLink} to={pathTo(`product/${product.Name}`)}>
        //         <Button
        //           title={t('shell:learnMore')}
        //           border={false}
        //           uppercase
        //           fontColor={colors.white}
        //           fontWeight={FontWeight.bold}
        //           bgColor={colorService.generateGradient(
        //             product.theme?.firstOptionalColor || colors.green,
        //             product.theme?.secondOptionalColor || colors.yellow,
        //             180
        //           )}
        //           shadowColor="0px 2px 10px rgba(0, 0, 0, 0.43)"
        //         />
        //       </Link>
        //     </div>
        //   </div>
        // ))
        <div className={style.loader}>
          <Spin />
        </div>
      )}
    </div>
  );

  const renderContent = (data: StrapiDeliveries | null) => (
    <Element name="deliveryContent" className={style.blockContent}>
      {data ? (
        <div className={style.delivery}>
          <h2 className={style.deliveryTitle}>{data.title}</h2>
          {path === pathName.delivery && (
            <div className={style.deliveryHead}>
              <img src={`${process.env.STRAPI_API_URL}/uploads/logistics_1_f20776730b.png`} alt="" />
              <h4>Мы можем доставить товар в почти любую точку мира!</h4>
            </div>
          )}
          <div className={style.deliverDescription}>
            <ReactMarkdown
              transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
              children={data?.additionalTitle || ''}
              remarkPlugins={[remarkGfm]}
            />
          </div>
          {data.blocks &&
            data.blocks.map((block, index) => {
              switch (replaceStrapiComponentName(block?.__component)) {
                case StrapiBlocksName.ordering:
                  return (
                    <React.Fragment key={block?.id || index}>
                      {path === pathName.delivery && !isMD ? (
                        <OptionItem title={block?.title || ''} isHidden={false}>
                          <div className={style.deliveryOrdering}>
                            {block?.description && (
                              <div className={style.orderingDescriotn}>
                                <ReactMarkdown
                                  transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                  children={block.description}
                                  remarkPlugins={[remarkGfm]}
                                />
                              </div>
                            )}
                            <div className={style.orderingList}>
                              {block?.orderItem &&
                                block.orderItem.map((item, index) => (
                                  <div className={style.orderItem} key={index}>
                                    <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                                    <div className={style.orderItemInfo}>
                                      <ReactMarkdown
                                        transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                        children={item?.description || ''}
                                        remarkPlugins={[remarkGfm]}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </OptionItem>
                      ) : (
                        <div className={style.deliveryOrdering}>
                          {block?.title && <h3 className={style.orderingTitle}>{block.title}</h3>}
                          {block?.description && (
                            <div className={style.orderingDescriotn}>
                              <ReactMarkdown
                                transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                children={block.description}
                                remarkPlugins={[remarkGfm]}
                              />
                            </div>
                          )}
                          <div className={style.orderingList}>
                            {block?.orderItem &&
                              block.orderItem.map((item, index) => (
                                <div className={style.orderItem} key={index}>
                                  <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                                  <div className={style.orderItemInfo}>
                                    <ReactMarkdown
                                      transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                      children={item?.description || ''}
                                      remarkPlugins={[remarkGfm]}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                case StrapiBlocksName.waysOfPayment:
                  return (
                    <React.Fragment key={block?.id || index}>
                      {path === pathName.delivery && !isMD ? (
                        <OptionItem title={block?.title || ''}>
                          <div className={style.deliveryPayment}>
                            <div className={style.paymentList}>
                              {block?.paymentItem &&
                                block.paymentItem.map((item, index) => (
                                  <div className={style.paymentItem} key={index}>
                                    <div className={style.paymentIcons}>
                                      <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                                      {item?.additionalPicture && (
                                        <img src={`${process.env.STRAPI_API_URL}${item?.additionalPicture?.url}`} alt="" />
                                      )}
                                    </div>
                                    <div className={style.paymentInfo}>
                                      <ReactMarkdown
                                        transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                        children={item?.description || ''}
                                        remarkPlugins={[remarkGfm]}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <div className={style.paymentDescription}>
                              <ReactMarkdown
                                transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                children={block?.description || ''}
                                remarkPlugins={[remarkGfm]}
                              />
                            </div>
                          </div>
                        </OptionItem>
                      ) : (
                        <div className={style.deliveryPayment}>
                          <h3 className={style.paymentTitle}>{block?.title}</h3>
                          <div className={style.paymentList}>
                            {block?.paymentItem &&
                              block.paymentItem.map((item, index) => (
                                <div className={style.paymentItem} key={index}>
                                  <div className={style.paymentIcons}>
                                    <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                                    {item?.additionalPicture && (
                                      <img src={`${process.env.STRAPI_API_URL}${item?.additionalPicture?.url}`} alt="" />
                                    )}
                                  </div>
                                  <div className={style.paymentInfo}>
                                    <ReactMarkdown
                                      transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                      children={item?.description || ''}
                                      remarkPlugins={[remarkGfm]}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className={style.paymentDescription}>
                            <ReactMarkdown
                              transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                              children={block?.description || ''}
                              remarkPlugins={[remarkGfm]}
                            />
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                case StrapiBlocksName.questionList:
                  return (
                    <div className={style.infoList} key={index}>
                      {block?.Question &&
                        block.Question.map((question, index) => (
                          <React.Fragment key={index}>
                            {path === pathName.delivery && !isMD ? (
                              <OptionItem title={question?.title || ''}>
                                <div className={style.question}>
                                  <div className={style.questionDesc}>
                                    <ReactMarkdown
                                      transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                      children={question?.description || ''}
                                      remarkPlugins={[remarkGfm]}
                                    />
                                  </div>
                                </div>
                              </OptionItem>
                            ) : (
                              <div className={style.question}>
                                <h4 className={style.questionTitle}>{question?.title}</h4>
                                <div className={style.questionDesc}>
                                  <ReactMarkdown
                                    transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                    children={question?.description || ''}
                                    remarkPlugins={[remarkGfm]}
                                  />
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  );
                case StrapiBlocksName.refundProblem:
                  return (
                    <div className={style.refundProblem} key={index}>
                      <h2 className={style.refundProblemTitle}>{block?.title}</h2>
                      <div className={style.refundProblemDesc}>
                        <ReactMarkdown
                          transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                          children={block?.description || ''}
                          remarkPlugins={[remarkGfm]}
                        />
                      </div>
                      <div className={style.problemList}>
                        {block?.refundQuestion &&
                          block.refundQuestion.map((problem, index) => (
                            <div className={style.problemItem} key={problem?.id || index}>
                              <div className={style.problemLabel}>{problem?.label}</div>
                              <div className={style.problemValue}>
                                <ReactMarkdown
                                  transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                  children={problem?.value || ''}
                                  remarkPlugins={[remarkGfm]}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                      {block?.CTA?.buttonTitle && block?.CTA?.btnLink && (
                        <a className={style.refundLink} href={block.CTA.btnLink} target="_blank" rel="noreferrer">
                          <Button title={block.CTA.buttonTitle} border={false} uppercase fontSize={16} fontWeight={FontWeight.bold} />
                        </a>
                      )}
                    </div>
                  );
                default:
                  return null;
              }
            })}
          <div className={style.deliveryExtraInfo}>
            <ReactMarkdown
              transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
              children={data?.extraInfo || ''}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        </div>
      ) : (
        <div className={style.loader}>
          <Spin />
        </div>
      )}
      {path === pathName.delivery && (
        <div className={style.deliveryHelp}>
          <h4>Необходима помощь?</h4>
          <span>Напишите в WhatsАpp</span>
          <a className={style.deliveryHelpLink} href="#" target="_blank">
            <Button title="Задать вопрос WhatsApp " border uppercase fontSize={16} fontWeight={FontWeight.bold} />
          </a>
        </div>
      )}
    </Element>
  );

  return (
    <IndexLayout>
      <Page>
        <LayoutBG background={`url(${process.env.STRAPI_API_URL}/uploads/fon_1_min_81f32f0b37.png) center top / cover no-repeat`}>
          <Container className={style.fEContainer}>
            <div className={style.fEPromo}>
              <div className={style.fEPromoContent}>
                <h2 className={style.fETitle}>
                  Занимайся спортом с <span>MedushkinaFit</span>
                </h2>
                <p className={style.fEDescription}>Профессиональные товары и тренажёры для комфортных и безопасных тренировок</p>
              </div>
            </div>
          </Container>
        </LayoutBG>
        <div className={style.fEContentWrap}>
          <div className={style.fEContent}>
            <AdaptiveLayout onDesktop onTablet>
              <div className={style.navigation}>
                {fENav.map(item => (
                  <div
                    className={cx(style.navItem, { [style.navItemActive]: item.path === path })}
                    key={item.id}
                    onClick={() => setPath(item.path)}
                  >
                    {item.id === 1 && <span>Каталог товаров</span>}
                    <img src={`${process.env.STRAPI_API_URL}${item.icon}`} alt="" />
                    <div className={style.navItemLabel}>{item.label}</div>
                  </div>
                ))}
              </div>
              {path === pathName.fitnessEquipment
                ? productsList
                : path === pathName.delivery
                  ? renderContent(delivery)
                  : renderContent(refund)}
            </AdaptiveLayout>
            <AdaptiveLayout onMobile>
              <div className={style.navigation}>
                {fENav.map(item => (
                  <React.Fragment key={item.id}>
                    <div
                      className={cx(style.navItem, { [style.navItemActive]: item.path === path })}
                      onClick={() => {
                        setPath(item.path);
                        if (item.id !== 1 && path !== pathName.fitnessEquipment)
                          scroller.scrollTo('deliveryContent', {
                            duration: 1000,
                            delay: 150,
                            smooth: true,
                            offset: -150,
                          });
                      }}
                    >
                      <img src={`${process.env.STRAPI_API_URL}${item.icon}`} alt="" />
                      <div className={style.navItemLabel}>{item.label}</div>
                    </div>
                    {path === item.path && item.path === pathName.fitnessEquipment
                      ? productsList
                      : path === item.path && item.path === pathName.delivery
                        ? renderContent(delivery)
                        : path === item.path && item.path === pathName.refund
                          ? renderContent(refund)
                          : null}
                  </React.Fragment>
                ))}
              </div>
            </AdaptiveLayout>
          </div>
        </div>
      </Page>
    </IndexLayout>
  );
};

export default FitnessEquipmentPage;
