import { Currency } from '@altermeliora/payform-types';
import { ItemDiscount } from '../common-interfaces';
import { pathName } from './enums';

export const fakePromoOne: ItemDiscount[] = [
  {
    id: '3GJTNUilZSmIMJfC2mYcxB',
    title: 'Скидка на эспандеры',
    total: 20,
    currency: Currency.euro,
  },
];

export const fakePromoTwo: ItemDiscount[] = [
  {
    id: '4bm9USseYuElhpCty793zp',
    title: 'Скидка на мегасет',
    total: 5,
    currency: Currency.euro,
  },
  {
    id: '424EXZKxX82QjLbdt5ZjCf',
    title: 'Скидка на Фитнес',
    total: 10,
    currency: Currency.euro,
  },
];

export const searchRusultData = [
  {
    id: 1,
    title: 'Проект «1 уровень. Общая нутрициология»',
    description: 'Основы правильного и сбалансированного питания для здоровья и стройности. Базовые знания нутрициологии.',
    link: '',
  },
  {
    id: 2,
    title: 'Статья в блоге «Как начать качать ягодицы в домашних условиях?»',
    description: 'Основы правильного и сбалансированного питания для здоровья и стройности. Базовые знания нутрициологии.',
    link: '',
  },
  {
    id: 3,
    title: 'Проект «1 уровень. Общая нутрициология»',
    description: 'Основы правильного и сбалансированного питания для здоровья и стройности. Базовые знания нутрициологии.',
    link: '',
  },
  {
    id: 4,
    title: 'Статья в блоге «Как начать качать ягодицы в домашних условиях?»',
    description: 'Основы правильного и сбалансированного питания для здоровья и стройности. Базовые знания нутрициологии.',
    link: '',
  },
];

export const fENav = [
  {
    id: 1,
    label: 'Фитнес-оборудование',
    icon: '/uploads/Vector_a21ce2341a.png',
    path: pathName.fitnessEquipment,
  },
  {
    id: 2,
    label: 'Доставка и оплата',
    icon: '/uploads/bicycle_1_8d6bc64fd3.png',
    path: pathName.delivery,
  },
  {
    id: 3,
    label: 'Гарантия и возврат',
    icon: '/uploads/bicycle_1_9dd0162eb8.png',
    path: pathName.refund,
  },
];
